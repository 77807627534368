import React, { useEffect } from "react";

const Negation = () => {
    useEffect(() => {
        // After the component mounts, tell MathJax to typeset the math
        window.MathJax.typeset();
    }, []);

    return (

        <div className="content" >
            <section id="hero">
                <h1>
                The Negation Problem in Artificial Intelligence
                </h1>
                <h3>
                Jason Livingston
                </h3>
            </section>
        
            <article>
                <p>
                I want to start by broadly addressing that “AI” isn’t intelligent to the extent that humans are, in fact, complicated, 
                advanced neural networks sometimes struggle with tasks that are trivially simple to humans. One such task is the idea 
                of negation. Here I demonstrate, for a simple classification problem, an implementation if explicit negation, and show 
                an example of an image generating model failing to follow a negation command.
                </p>

                <p>
                A simple enough task delivered to DALL-E 3: “create a picture of downtown New York without any wombats climbing cars.” 
                What was produced in my attempt would be somewhat suitable for a prompt explicitly asking for wombats on cars to be included:
                </p>

                <img src="../images/wombat_car1.jpg" className="article-image"/>

                <p>
                This isn’t surprising. When this type of generative image model is trained, it is presented with text that describes what is 
                in images, and the images themselves. The model is specifically not trained with text about what is “not” in the images, 
                what the images are “without”, or what they “do not show”. To create text about the many subjects existing in the 
                universe which don’t appear in a picture would be a daunting, impossible task. Explicit negation by algorithms, achieved 
                by creating augmented training data that includes negation, I believe, will most likely not be a practical or useful 
                exercise, unless research determines that algorithms are capable of learning implicit negation from a sample of augmented 
                training data that describes far less than everything that might possibly be negated.
                </p>
                
                <p>
                Rather than train my own generative model, I wanted to take a simpler approach to look at negation. Using the well-known Iris dataset, I was interested in applying a strategy of:
                
                <ol>
                    <li>Splitting off a training subset (Xtrain,ytrain).</li>
                    <li>Creating augmented training data, which pairs class 0 labels with predictors for classes 1 and 2, class 1 labels with predictors for classes 0 and 2, and class 2 labels with predictors for classes 0 and 1.</li>
                    <li>Creating a “Negation” binary feature, which is “0” for the original training subset, and “1” for the newly created augmented subset.</li>
                </ol>

                </p>

                <p>
                I want to observe that the classifier, an MLPClassifier, can predict similarly well to the simple non-augmented classification 
                exercise on the testing subset (which in this case would be perfectly), but when the negation feature is “1”, see the probability
                 of the true class shrink towards zero, while the probabilities of the other classes increase. In this prediction paradigm, the 
                 prediction probabilities can be sampled from in the negative cases, to hopefully produce a prediction that is any of the non-true 
                 classes. 
                </p>

                <p>
                Indeed it is simple enough to process the data in this way, observe that the prediction of the original non-negated records is, 
                similar to the original classification problem, perfect, and that sampling from the negated probabilities produces a 
                non-deterministic prediction from among the non-true class options:
                </p>

                <pre>
                Confusion Matrix on testing holdout:<br></br>
                [<br></br>
                [10  0  0]<br></br>
                [ 0  9  0]<br></br>
                [ 0  0 11]<br></br>
                ]<br></br>
                Accuracy = 1.0<br></br> 
                <br></br> 
                Prediction Proba:<br></br>
                True 0 records, negated:<br></br> 
                [<br></br> 
                [0.    0.509 0.491]<br></br> 
                [0.    0.509 0.491]<br></br> 
                [0.    0.509 0.491]<br></br> 
                [0.    0.509 0.491]<br></br> 
                [0.    0.509 0.491]<br></br> 
                ] <br></br> 
                <br></br> 
                True 0 record, not negated:<br></br> 
                [1. 0. 0.] <br></br> 
                <br></br> 
                Negated predictions for classes 0, 1 and 2:<br></br> 
                [<br></br> 
                [0.    0.509 0.491]<br></br> 
                [0.507 0.001 0.492]<br></br> 
                [0.484 0.516 0.001]<br></br> 
                ] <br></br> 
                <br></br> 
                Prediction (sampled) from the probabilities above:<br></br> 
                [<br></br> 
                [2]<br></br> 
                [1]<br></br> 
                [1]<br></br> 
                [2]<br></br> 
                [1]<br></br> 
                [0]<br></br> 
                [2]<br></br> 
                [0]<br></br> 
                [0]<br></br> 
                ]<br></br> 

                </pre>

                <p>
                We see in the negated predictions for classes 0, 1 and 2, that prediction probabilities indeed shift into the other classes. 
                In this paradigm, I’m then curious: is it possible to only create augmented training data for two of the classes, leaving 
                the third class out of the negated augmented data, and learn implicit negation for the third class? In this setup, we would 
                pair (for negation) class label 0 with records of classes 1 and 2, and class label 1 with records of classes 0 and 2. 
                We would expect then that class 2 records could be negated as “not” belonging to 0 or 1, while class 0 records could only 
                be negated to class 1, and class 1 records could only be negated to class 0. The result: 
                </p>
                
                <pre>
                Confusion Matrix on testing holdout:<br></br> 
                [<br></br> 
                [10  0  0]<br></br> 
                [ 0  9  0]<br></br> 
                [ 0  0 11]<br></br> 
                ] <br></br> 
                Accuracy = 1.0<br></br> 
                <br></br> 
                Prediction Proba:<br></br> 
                True 0 records, negated:<br></br> 
                [<br></br> 
                [0. 1. 0.]<br></br> 
                [0. 1. 0.]<br></br> 
                [0. 1. 0.]<br></br> 
                [0. 1. 0.]<br></br> 
                [0. 1. 0.]<br></br> 
                ] <br></br> 
                <br></br> 
                True 0 record, not negated:<br></br> 
                [1. 0. 0.] <br></br> 
                <br></br> 
                Negated predictions for classes 0, 1 and 2:<br></br> 
                [<br></br> 
                [0.    1.    0.   ]<br></br> 
                [1.    0.    0.   ]<br></br> 
                [0.491 0.509 0.   ]<br></br> 
                ] <br></br> 
                <br></br> 
                Prediction (sampled) from the probabilities above:<br></br> 
                [<br></br> 
                [1]<br></br> 
                [1]<br></br> 
                [1]<br></br> 
                [1]<br></br> 
                [1]<br></br> 
                [0]<br></br> 
                [1]<br></br> 
                [0]<br></br> 
                [0]<br></br> 
                ]<br></br> 
                </pre>

                <p>
                We can see in the negated probability prediction, as expected, because class 0 labels were never paired with class 2 
                predictions for negation, so, negating a class 0 record to class 2 would be highly unlikely (the probabilities are rounded). 
                Similarly, because class 1 labels were only paired with class 0 records for learning negation, this class will not likely be 
                negated to class 2. Class 2 labels were paired with records of class 0 and class 1 for learning negation, so class 2 is somewhat 
                equally likely to be negated to either class 1 or class 0. The implicit ability to negate a class 1 or class 0 record to class 
                2 was not achieved in this design. The algorithm “knows” class 2 exists, but can only predict class 2 when it is a true class 2 
                record, not a negated class 1 or class 0 record.
                </p>

                <p>
                Perhaps a different algorithm or data design can learn implicit negation, but I’m skeptical that any design would help an 
                algorithm achieve generalized negation capabilities like what a human can perform. As more people begin using “AI” products 
                to enhance their lives, I think it is important that people are educated about what these algorithms can and cannot do. 
                </p>



            </article>
        </div>

    );
};

export default Negation;