import React from "react";

const GLMDistributions = () => {

    return (

        <div className="content" >
            <section id="hero">
                <h1>
                Decoupling Generalized Linear Model Distribution Choices from Canonical Link Functions
                </h1>
                <h3>
                Jason Livingston
                </h3>
            </section>

        
        <div style={{ height: '100%', width: '100%' }}>   
            <iframe
                src="/model.html"
                style={{ border: 'none', height: '1200px', width: '100%' }}
                title="GLM Distribution Notebook"
            ></iframe>
        </div>
            
        </div>

    );
};

export default GLMDistributions;