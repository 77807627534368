import React, { useEffect } from "react";

const MTRegression = () => {
    useEffect(() => {
        // After the component mounts, tell MathJax to typeset the math
        window.MathJax.typeset();
    }, []);

    return (

        <div className="content" >
            <section id="hero">
                <h1>
                Multi-Target Regression Coefficients Resolve to Single-Target Regression Expected Values
                </h1>
                <h3>
                Jason Livingston
                </h3>
            </section>
        
            <article>
                <p>
                This is a simple demonstration and justification for how and why the coefficients in a 
                multi-target regression coefficient matrix will sum to the coefficients in the equivalent 
                single-target regression's coefficient vector, over axis 1. If the single-target regression 
                problem is bounded by some condition, perhaps that all targets sum to a constant value, as 
                in a percentage-share problem where 100% is the total of the targets, the sum of the 
                multi-target regression predictions will also be bounded to the same constraint. 
                </p>

                <p>
                Consider a regression task with K targets (rather than a typical single-target regression), 
                where each target is a percentage allocated to a single entity, and all of the target 
                proportions sum to 1. Linear-algebraically, nothing about this regression is different 
                from single-target regression, and the coefficients can be solved explicitly:
                </p>

                <p>
                \( B = (X^t \cdot X)^{-1} \cdot (X^t \cdot Y) \)
                </p>

                <p>
                If input matrix X has M predictors (I always include the intercept in M), with K targets, 
                B is a matrix of shape MxK.
                </p>

                <p>
                Consider the condition that targets in Y sum to 1 (over axis 1). This is a dot product of 
                Y with a vector of ones, size K. The condition that Y sums to 1 can be written as:
                </p>
                
                <p>
                {String.raw`\( Y \cdot 1 = Y_{\text{sum}} = 1 \)`}
                </p>

                <p>
                {String.raw`\( Y \cdot 1 = Y_{\text{sum}} \)`} has no variance, it is a constant, 
                and so a regression for {String.raw`\( Y_{\text{sum}} \)`} would return a non-zero 
                intercept (\( b_0 = 1 \)). 
                
                Feature coefficients would be equal to 0 because 
                the features have no correlation with the target (because the target has no variance).
                </p>
                
                <p>
                The multiplication of a Kx1 vector of ones onto Y in order to sum over Y can be symetrically applied 
                to the other side of the regression, and in doing so, B of shape MxK is consolidated by summation 
                into shape Mx1:
                </p>
                
                <p>
                \( Y = X \cdot B + Norm(0,\sigma, (N,K)) \)
                </p>
                
                <p>
                {String.raw` \( Y \cdot 1 = Y_{\text{sum}} = X \cdot B \cdot 1 + Norm(0,\sigma, (N,K)) \cdot 1 \) `}
                </p>
                
                <p>
                {String.raw`\( Y_{\text{sum}} = X \cdot B_{\text{sum}} + Norm(0,\sigma) \)`}
                </p>
                
                <p>
                Here, {String.raw`\( B_{\text{sum}} \)`} is the vector of coefficients that would be calculated from 
                a regression against {String.raw`\( Y_{\text{sum}} \)`}, which has a 
                constant value of 1, or no variance. So, while all of the feature coefficients 
                in \( B \) sum over axis 1 to equal 0, the intercept coefficients in \( B \) sum to 1. A demonstration 
                of this with sample data is published <a href="https://github.com/JLivingston01/ml-research/blob/master/one-based-models/notebooks/model.ipynb" target="_blank" class="hyperlink">here</a>.
                </p>

            </article>
        </div>

    );
};

export default MTRegression;