import React from "react";

const RegressionInversion = () => {

    return (

        <div className="content" >
            <section id="hero">
                <h1>
                Inverting Regressions y~f(x) Yielding Sub-optimial Estimators for x~g(y)
                </h1>
                <h3>
                Jason Livingston
                </h3>
            </section>

        
        <div style={{ height: '100%', width: '100%' }}>   
            <iframe
                src="/regression.html"
                style={{ border: 'none', height: '1200px', width: '100%' }}
                title="Regression Inversion Notebook"
            ></iframe>
        </div>
            
        </div>

    );
};

export default RegressionInversion;