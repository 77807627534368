import React from "react";

const Contact = () => {
    return (
        <div className="content">
                        
            <section id="hero">
                <img src="images/new-headshot.jpg"  />
                <h1>
                Jason Livingston
                </h1>
                <h3>
                Contact
                </h3>
            </section>

            <section >
                <p>
                If you'd like to connect or collaborate, please reach out via <a href="https://linkedin.com/in/jason-livingston-a8bb9325" target="_blank" class="hyperlink">
                    LinkedIn</a>, or <a href="mailto:jay.s.livingston@gmail.com?subject=Contact Jason" target="_blank" class="hyperlink">
                    email me</a>
                </p>
            </section>

        </div>
    );
};

export default Contact;