import React from "react";
import { Link } from 'react-router-dom';

const Soccer = () => {
    return (
        <div className="content">
            
            <section id="hero">
                <img src="images/pregame.JPG"  />
                <h1>
                Jason Livingston
                </h1>
                <h3>
                Soccer and Goalkeeping
                </h3>
            </section>
        

            <section >
                <p>
                    I am a Goalkeeping coach and a former collegiate 
                    and club soccer player with training experience in professional, 
                    amateur and youth settings. 
                    While studying and playing as a college senior, 
                    I worked with the Austin Aztex during the 2015 USL season.
                </p>
                <p>I am available to coach goalkeepers in the NYC area. Feel free to <Link to="/contact">contact me</Link> if you would like to 
                    connect over soccer.
                </p>
            </section>
            
            <section >
                <h1>
                    Playing
                </h1>
                <h3>
                    Texas A&M University - Corpus Christi Men's Club, TCSL | 2011 - 2014
                </h3>
                <h5>
                    Captain, Club President
                </h5>
                <h3>
                    Concordia University Texas Tornados, NCAA DIII | 2014 - 2015
                </h3>
                <h5>
                    National College Athlete Honor Society inductee, 2016
                </h5>
                <h1>
                    Licensing
                </h1>
                <h3>
                    United Soccer Coaches/NSCAA 
                </h3>
                <h5>
                    Goalkeeping Level 1
                </h5>
                <h3>
                    United States Soccer Federation
                </h3>
                <h5>
                    USSF Grassroots 11v11
                </h5>
            </section>

            <section >
                <h1>
                    Coaching
                </h1>
                <h3>
                    Procat Goalkeeping Academy, Corpus Christi, TX | 2011 - 2013
                </h3>
                <h5>
                    Goalkeeping Trainer
                </h5>
                <h3>
                    John Paul II High School, Corpus Christi, TX | 2013 - 2014
                </h3>
                <h5>
                    Volunteer Goalkeeping and Assistant Coach
                </h5>
                <h3>
                    Austin Texans, Austin, TX | 2015 - 2016
                </h3>
                <h5>
                    Goalkeeping Trainer
                </h5>
                <h3>
                    Austin Aztex, Austin, TX | USL Pro | 2015
                </h3>
                <h5>
                    Assistant to Goalkeeping, Strength and Conditioning and Athletic Training
                </h5>
                <h3>
                    Astoria Knights FC, New York, NY | UPSL | 2020-2021
                </h3>
                <h5>
                    Goalkeeping Coach
                </h5>
            </section>

        </div>
    );
};

export default Soccer;