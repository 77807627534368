import React from "react";

const WeightedAveraging = () => {

    return (

        <div className="content" >
            <section id="hero">
                <h1>
                Regression for Creating a Weighted Averaging Scheme
                </h1>
                <h3>
                Jason Livingston
                </h3>
            </section>

        
        <div style={{ height: '100%', width: '100%' }}>   
            <iframe
                src="/weighted-average-models.html"
                style={{ border: 'none', height: '1200px', width: '100%' }}
                title="Weighted Averaging Notebook"
            ></iframe>
        </div>
            
        </div>

    );
};

export default WeightedAveraging;