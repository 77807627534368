import React, { useEffect } from "react";

const Sinusoids = () => {
    useEffect(() => {
        // After the component mounts, tell MathJax to typeset the math
        window.MathJax.typeset();
    }, []);

    return (

        <div className="content" >
            <section id="hero">
                <h1>
                Sinusoidal Features for Periodic Models, and Handling the Phase Shift
                </h1>
                <h3>
                Jason Livingston
                </h3>
            </section>
        
            <article>
                <p>
                When processing a time series that shows sinusoidal behavior due to seasonality, 
                where a year-long period, monthly or weekly periods may be common, sinusoidal features can be a useful choice, 
                even if not using an autoregressive technique for forecast modeling. 
                A question that occurred to me: 
                how should the phase shift of a sinusoidal trend be handled when passing sinusoids to a model for learning the periodic trend?
                </p>

                <p>
                Here I'm not addressing any discussion of the Fourier Transform, 
                which could estimate what hidden frequencies are present in periodic trend. 
                For highly seasonal series, I feel it is largely sufficient to make reasonable guesses, with yearly, monthly, 
                and weekly frequencies as appropriate. 
                </p>

                <p>
                One dataset that shows a highly seasonal trend for exploring the use of sinusoids is openml.org dataset 43843: 
                Eighty Years of Canadian Climate Data. 
                Pictured here, the mean Toronto daily temperature is aligned with yearly periodic sine and cosine waves. 
                Note that these waves are out of phase with the target series MEAN_TEMPERATURE_TORONTO:
                </p>
                
                <img src="../images/sinu_1.png" className="article-image"/>

                <p>
                If we say that the target series can be modeled by a sine wave with a defined frequency and phase shift:
                </p>
                
                <p>
                \( Y \sim B_1*sin⁡(2*π*1/365.25*x+φ)+B_0 \)
                </p>

                <p>
                It is reasonable to make a guess about the ordinary frequency of the wave, 
                but the phase shift is less straightforward to determine. 
                It is also trivial to determine because of the Sum of Angles identity for sine:
                </p>

                <p>
                \( sin⁡(a+b) = sin⁡(a)cos⁡(b)+sin⁡(b)cos⁡(a) \)
                </p>

                <p>
                Rather than assuming the value of φ, lets use a pair of cosine and sine waves together, and implicitly learn φ during the regression:
                </p>
                
                <p>
                \( Y \sim B_1*sin⁡(2*π*1/365.25*x+φ)+B_0 \)
                </p>

                <p>
                \( Y \sim B_1*cos⁡(φ)*sin⁡(2*π*1/365.25*x)+B_1*sin⁡(φ)*cos⁡(2*π*1/365.25*x)+B_0 \)
                </p>
                

                <p>
                \( Y \sim B_2*sin⁡(2*π*1/365.25*x)+B_3*cos⁡(2*π*1/365.25*x)+B_0 \)
                </p>

                <p>
                With a pair of sine and cosine waves together, the value of the true phase shift is implicitly learned in the coefficients 
                of these two features. So, if you’ve made reasonable guesses for the periods you need to consider, 
                the phase shift can be handled by using a pair of sine and cosine features, with the same period, together. The 
                same dataset mentioned above, graphed now with an OLS regression fit of the sine and cosine pair shown:
                </p>
                
                <img src="../images/sinu_2.png" className="article-image"/>

            </article>
        </div>

    );
};

export default Sinusoids;