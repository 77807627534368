import React from "react";

const NotFound = () => {
    return (
        <div className="content">
            
            <section id="hero">
                <img src="images/new-headshot.jpg" class="header_img" />
                <h1>
                Jason Livingston
                </h1>
                <h3>
                404 - Not Found
                </h3>
            </section>
        

            <section >
                <p>
                    You may have ended up here by typing a route not defined in this application, or by 
                    following a Google link to static content that no longer exists. 
                </p>
                <p>
                    This site was previously hosted statically on GitHub Pages, but is now written in React and 
                    hosted on Google Firebase, so Google's search engine may not know about the most current content. 
                </p>
                <p>
                    Use the Nav menu to get back to where you want to be!
                </p>
            </section>

        </div>
    );
};

export default NotFound;